html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Poppins" !important;
  // font-size: 18px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.disable-pointer-events {
  pointer-events: none;
}

.visibilty-not-hiddent {
  visibility: visible;
}

.visible-hidden {
  visibility: hidden;
}

.bold-text {
  font-weight: bold !important;
}

a,
button,
img {
  cursor: pointer;
  border-style: none;
}

input {
  padding: 5px !important;
}

.button-orange {
  background: #e99210;
  border-color: #e99210;
}

.button-grey {
  background: #404040;
  border-color: #404040;
}

::-webkit-scrollbar {
  margin-left: 10px;
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dcdcdc;
  border-radius: 6px;
  transform: rotate(90deg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 6px;
  transform: rotate(90deg);
}

.hide {
  display: none !important;
}
