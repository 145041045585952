@use "../../../../common/css/base" as base;

.market-header {
  flex-direction: column;
}

.settings-content-header-text-holder {
  display: flex;
  width: 320px;
  height: 15px;
  align-items: center;
  flex-shrink: 0;
}

.market-grid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-auto-flow: column;
  overflow: none;
  height: 90%;
}

#new-market {
  [aria-label="display component"] {
    color: base.$ap-white;
    font-family: base.$font;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.44px;
    display: flex;
    align-items: center;
  }

  [aria-label="input component"] {
    color: base.$ap-black;
    font-family: base.$font;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.44px;
    display: flex;
    align-items: center;
    width: 140px;
    height: 25px;
    border: 0.25px solid var(--ap-black, #404040);
    background: var(--ap-light-grey, #f2f2f2);
  }
}

.market-container {
  display: flex;
  width: 160px;
  height: 25px;
  padding: 4px 8px;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  justify-content: space-between;
}

.market-name {
  color: base.$ap-white;
  font-family: base.$font;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.44px;
}

.market-creator {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 25px;
  padding: 9px 0px 9px 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.market-creator-header-text {
  color: base.$ap-white;
  font-family: base.$font;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.44px;
}
