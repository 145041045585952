@use "../../../common/css/base" as base;

.settings-holder {
  display: flex;
  width: 96%;
  flex-direction: column;
  border-radius: 0px;
  margin: 18px;
  height: -webkit-fill-available;
  flex: 1;
}

.setting-tab {
  display: flex;
  width: 130px;
  height: 30px;
  padding: 0px 10px 0px 10px;
  align-items: center;
  flex-shrink: 0;
}

.setting-tab-text-selected {
  color: base.$ap-black;
  background-color: base.$ap-white;
}

.setting-tab-text-not-selected {
  color: base.$ap-white;
}

.setting-tab-text {
  font-family: base.$font;
  font-size: var(--custom-settings-font-size);
  font-style: normal;
  font-weight: 400;
  line-height: var(--custom-settings-line-height);
}

.settings-tab-content-container {
  flex-direction: row;
  margin: 20px 0px 20px 0px;
  height: 96%;
}

.settings-tab-container {
  width: 20%;
  flex-direction: column;
  border-right: 0.5px solid white;
}

.settings-content-container {
  margin: 0px 20px 0px 20px;
}

.settings-content-header-text {
  color: base.$ap-yellow;
  font-family: base.$font;
  font-size: 11.74px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.44px;
}

.settings-content-sub-header-text {
  color: base.$ap-white;
  font-family: base.$font;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.44px;
}

.settings-button-container {
  gap: 20px;
}

.settings-button {
  display: flex;
  width: 65px;
  height: 30px;
  padding: 5.872px 17.615px;
  justify-content: center;
  align-items: center;
  gap: 11.743px;
  flex-shrink: 0;
  border-radius: 3.523px;
}

.settings-button-text {
  color: base.$ap-black;
  text-align: center;
  font-family: Poppins;
  font-size: 10.275px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
