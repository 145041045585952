@use "../../common/css/base" as base;

.drag-text {
  display: flex;
  width: 228.639px;
  height: 12.33px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--AP-white, #fff);
  font-family: Poppins;
  font-size: 17.615px;
  font-style: normal;
  font-weight: 600;
  line-height: 24.661px;
}

@media screen and (max-width: 1366px) {
  .header-button-v4-text {
    display: flex;
    width: 91.646px;
    height: 28px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: var(--AP-light-gray, #f2f2f2);
    text-align: center;
    font-family: Poppins;
    line-height: 13px;
  }
  .header-button-v4 {
    display: flex;
    width: 105.33px;
    height: 33.615px;
    padding: 5.872px;
    gap: 5.872px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: var(--AP-black, #2e2e2e);
    box-shadow: 0px 7.046px 7.046px 0px rgba(0, 0, 0, 0.25);
  }
  .choose-file-button-size {
    width: 76.33px;
    height: 17.615px;
    padding: 5.872px;
    gap: 5.872px;
    border-radius: 3.523px;
    box-shadow: 0px 4.6972479820251465px 4.6972479820251465px 0px
      rgba(0, 0, 0, 0.25);
  }
  .choose-button-text-size {
    text-wrap: nowrap;
    font-size: 11.743px;
    font-weight: 600;
    line-height: 17.545px;
  }
  .drag-continer-text-size {
    font-size: 11.743px;
    font-weight: 600;
    line-height: 16.44px;
  }
  .d-actions-container {
    gap: 20px;
  }
  .user-container {
    gap: 6.25px;
  }
  .upload-button-size {
    width: 65px;
    height: 30px;
    padding: 5.872px 17.615px;
    gap: 11.743px;
    border-radius: 3.523px;
  }
  .upload-button-text-size {
    font-size: 10.275px;
  }
  .user-details-text-size {
    font-size: 12.532px;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  .header-button-v4-text {
    display: flex;
    width: 111.646px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: var(--AP-light-gray, #f2f2f2);
    text-align: center;
    font-family: Poppins;
    font-size: 15.615px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }
  .header-button-v4 {
    display: flex;
    width: 125px;
    height: 40px;
    padding: 8.807px;
    justify-content: center;
    align-items: center;
    gap: 8.807px;
    flex-shrink: 0;

    background: var(--AP-black, #2e2e2e);
    // box-shadow: 0px 7.046px 7.046px 0px rgba(0, 0, 0, 0.25);
    filter: drop-shadow(4px 8px 10px #656565);
  }
  .choose-file-button-size {
    width: 81.46px;
    height: 18.8px;
    padding: 6.266px;
    gap: 6.266px;
    border-radius: 3.76px;
    box-shadow: 0px 5.012844562530518px 5.012844562530518px 0px
      rgba(0, 0, 0, 0.25);
  }
  .choose-button-text-size {
    // width: 73.939px;
    text-wrap: nowrap;
    font-size: 12.53px;
    font-weight: 600;
    line-height: 17.545px;
    // height: 8.772px;
  }
  .drag-continer-text-size {
    font-size: 12.53px;
    font-weight: 600;
    line-height: 17.545px;
  }
  .d-actions-container {
    gap: 21.34px;
  }
  .user-container {
    gap: 6.25px;
  }
  .upload-button-size {
    width: 69.367px;
    height: 32.016px;
    padding: 6.266px 18.798px;
    gap: 12.532px;
    border-radius: 3.76px;
  }
  .upload-button-text-size {
    font-size: 10.966px;
  }
  .user-details-text-size {
    font-size: 12.532px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 2048px) {
  .header-button-v4-text {
    display: flex;
    width: 131.646px;
    height: 37px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: var(--AP-light-gray, #f2f2f2);
    text-align: center;
    font-family: Poppins;
    font-size: 17.615px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  .header-button-v4 {
    display: flex;
    width: 155px;
    height: 45px;
    padding: 8.807px;
    justify-content: center;
    align-items: center;
    gap: 8.807px;
    flex-shrink: 0;

    background: var(--AP-black, #2e2e2e);
    box-shadow: 0px 7.046px 7.046px 0px rgba(0, 0, 0, 0.25);
  }
  .choose-file-button-size {
    width: 114.5px;
    height: 26.422px;
    padding: 8.807px;
    gap: 8.807px;
    border-radius: 5.284px;
    box-shadow: 0px 7.045872211456299px 7.045872211456299px 0px
      rgba(0, 0, 0, 0.25);
  }
  .choose-button-text-size {
    text-wrap: nowrap;
    font-size: 17.615px;
    font-weight: 600;
    line-height: 24.661px;
  }
  .drag-continer-text-size {
    font-size: 17.615px;
    font-weight: 600;
    line-height: 24.661px;
  }
  .d-actions-container {
    gap: 30px;
  }
  .user-container {
    gap: 6.25px;
  }
  .upload-button-size {
    width: 97.5px;
    height: 45px;
    padding: 8.807px 26.422px;
    gap: 17.615px;
    border-radius: 5.284px;
  }
  .upload-button-text-size {
    font-size: 15.413px;
  }
  .user-details-text-size {
    font-size: 17.615px;
  }
}

@media screen and (min-width: 2049px) {
  .header-button-v4-text {
    display: flex;
    width: 131.646px;
    height: 37px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: var(--AP-light-gray, #f2f2f2);
    text-align: center;
    font-family: Poppins;
    font-size: 17.615px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }
  .header-button-v4 {
    display: flex;
    width: 155px;
    height: 45px;
    padding: 8.807px;
    justify-content: center;
    align-items: center;
    gap: 8.807px;
    flex-shrink: 0;

    background: var(--AP-black, #2e2e2e);
    box-shadow: 0px 7.046px 7.046px 0px rgba(0, 0, 0, 0.25);
  }
  .choose-file-button-size {
    width: 114.5px;
    height: 26.422px;
    padding: 8.807px;
    gap: 8.807px;
    border-radius: 5.284px;
    box-shadow: 0px 7.045872211456299px 7.045872211456299px 0px
      rgba(0, 0, 0, 0.25);
  }
  .choose-button-text-size {
    text-wrap: nowrap;
    font-size: 17.615px;
    font-weight: 600;
    line-height: 24.661px;
  }
  .drag-continer-text-size {
    font-size: 17.615px;
    font-weight: 600;
    line-height: 24.661px;
  }
  .d-actions-container {
    gap: 30px;
  }
  .user-container {
    gap: 6.25px;
  }
  .upload-button-size {
    width: 97.5px;
    height: 45px;
    padding: 8.807px 26.422px;
    gap: 17.615px;
    border-radius: 5.284px;
  }
  .upload-button-text-size {
    font-size: 15.413px;
  }
  .user-details-text-size {
    font-size: 17.615px;
  }
}

.choose-file-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: base.$ap-white;
  flex-shrink: 0;
}

.choose-button-text {
  color: base.$ap-black;
  text-align: center;
  font-family: base.$font;
  font-style: normal;
}

.drag-continer-text {
  font-family: base.$font;
  font-style: normal;
  color: base.$ap-white;
  gap: 8.19px;
}

.image-container {
  display: flex;
  // width: calc(100% / 3);
  align-items: center;
  justify-content: left;
  gap: 12px;
}

.actions-container {
  display: flex;
  // width: calc(100% / 3);
  align-items: center;
  justify-content: end;
}

.action-buttons {
  font-family: base.$font;
  font-style: normal;
  font-weight: 600;
  color: base.$ap-white;
}

.user-details {
  font-family: base.$font;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
  text-align: right;
  color: #ffffff;
}

input[type="file"]::file-selector-button {
  background: #404040;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.uploader-area {
  display: flex;
  width: calc(100% / 3);
}
