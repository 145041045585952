@use "../../../../common/css/base" as base;
.value-edited {
  background-color: #b6dea8 !important;
}

.value {
  font-family: base.$font;
  font-size: var(--custom-edit-font-size);
  font-style: normal;
  font-weight: 400;
  padding: 0.3125em;
  line-height: 1.5em;
  color: base.$ap-black;
  height: auto;
  display: flex;
  align-items: center;
  width: 100%;
  resize: none;
  overflow: hidden;
}

.value-selected {
  background: base.$ap-medium-orange;
}

.value-not-selected {
  background: base.$ap-header-gray;
}
