$font: 'Poppins' !default;
$ap-black: #404040 !default;
$ap-yellow: #ffb000 !default;
$ap-white: #ffffff !default;

$ap-dialog-black: #4e4d4d;

$ap-header-gray: #dcdcdc !default;
$ap-light-gray: #f2f2f2 !default;
$ap-medium-gray: #b8b8b8;
$ap-medium-dark-gray: #777;

$ap-green: #2ba700;
$ap-deep-blue: #2e75b6;
$ap-dark-orange: #e99210;

$ap-medium-orange: #fad47f;
$ap-close-button-black: #1e1e1e;

@media screen and (max-width: 1365px) {
  :root {
    // --custom-font-size: 11.743px;
    --custom-font-size: 13.743px;
    --custom-user-impacted-font-size: 11.743px;

    // Dashboard
    --custom-dashboard-author-font-size: 10px;

    // Tab
    --custom-tab-height: 45px;
    --custom-tab-width: 150px;
    --custom-tab-gap: 2px;
    --custom-nav-width: 50px;

    // Page-Header
    --custom-page-header-border-element-height: 5px;
    --custom-page-header-border-thickness: 0.294px;
    --custom-page-header-height: 40px;
    --custom-page-header-padding: 8.09px; //20px;
    --custom-page-header-upload-button-font-size: 10.275px;

    // Upload Popup

    --custom-upload-logo-width: 169.731px;
    --custom-upload-logo-height: 22.5px;

    --custom-upload-dialog-width: 885px;
    --custom-upload-dialog-height: 510px;
    --custom-upload-dialog-border-radius: 5.618px;

    --custom-upload-grid-margin: 15px;
    --custom-upload-dialog-grid-header-font-size: 15px;
    --custom-upload-dialog-grid-header-line-height: 15.729px;

    --custom-upload-dialog-header-height: 45px;
    --custom-upload-dialog-header-content-gap: 13.15px;
    --custom-upload-dialog-header-filter-text-gap: 8px;
    --custom-upload-dialog-header-filter-gap: 14px;
    --custom-upload-dialog-header-filter-toggle-width: 45px;
    --custom-upload-dialog-header-filter-toggle-height: 22.05px;
    --custom-upload-dialog-header-filter-text-font-size: 15px;
    --custom-uploader-header-text-image-gap: 15px;

    --custom-uploader-header-sort-image-width: 13px;
    --custom-uploader-header-sort-image-height: 12.8px;
    --custom-uploader-header-sort-image-gap: 3px;

    --custom-upload-dialog-cell-padding: 11.235px;

    --custom-document-name-font-size: 15px;
    --custom-document-name-line-height: 21px;

    --custom-document-name-other-font-size: 12px;
    --custom-document-name-other-line-height: 8.988px;

    --custom-upload-dialog-date-font-size: 15px;
    --custom-upload-dialog-time-font-size: 15px;
    --custom-upload-dialog-line-height: 21px;
    --custom-upload-dialog-datetime-gap: 5.15px;

    --custom-close-width: 75px;
    --custom-close-height: 40px;
    --custom-close-padding: 5.618px 16.853px;
    --custom-close-gap: 11.235px;
    --custom-close-font-size: 13.125px;
    --custom-close-border-radius: 3.371px;

    --custom-settings-font-size: 10px;
    --custom-settings-line-height: 16.44px;

    // Edit Panel Dialog
    --custom-edit-header-tag-font-size: 20px;
    --custom-edit-header-tag-line-height: 20px;
    --custom-edit-font-size: 15px;
    --custom-edit-line-height: 20px;
    --custom-edit-header-font-weight: 700;
    --custom-edit-header-value-font-weight: 400;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1919px) {
  :root {
    --custom-font-size: 17.44px;
    --custom-user-impacted-font-size: 15.04px;

    // Dashboard
    --custom-dashboard-author-font-size: 12px;

    // Tab
    --custom-tab-height: 48.023px;
    --custom-tab-width: 160.078px;
    --custom-tab-gap: 2px;
    --custom-nav-width: 50px;

    // Header
    --custom-page-header-border-element-height: 5px;
    --custom-page-header-border-thickness: 0.313px;
    --custom-page-header-height: 45px;
    --custom-page-header-padding: 8.09px; //21.34px;
    --custom-page-header-upload-button-font-size: 10.966px;

    // Upload Popup

    --custom-upload-logo-width: 169.731px;
    --custom-upload-logo-height: 22.5px;

    --custom-upload-dialog-width: 885px;
    --custom-upload-dialog-height: 510px;
    --custom-upload-dialog-border-radius: 5.618px;

    --custom-upload-grid-margin: 15px;
    --custom-upload-dialog-grid-header-font-size: 15px;
    --custom-upload-dialog-grid-header-line-height: 15.729px;

    --custom-upload-dialog-header-height: 45px;
    --custom-upload-dialog-header-content-gap: 13.15px;
    --custom-upload-dialog-header-filter-text-gap: 8px;
    --custom-upload-dialog-header-filter-gap: 14px;
    --custom-upload-dialog-header-filter-toggle-width: 45px;
    --custom-upload-dialog-header-filter-toggle-height: 22.05px;
    --custom-upload-dialog-header-filter-text-font-size: 15px;
    --custom-uploader-header-text-image-gap: 15px;

    --custom-uploader-header-sort-image-width: 13px;
    --custom-uploader-header-sort-image-height: 12.8px;
    --custom-uploader-header-sort-image-gap: 3px;

    --custom-upload-dialog-cell-padding: 11.235px;

    --custom-document-name-font-size: 15px;
    --custom-document-name-line-height: 21px;

    --custom-document-name-other-font-size: 12px;
    --custom-document-name-other-line-height: 8.988px;

    --custom-upload-dialog-date-font-size: 15px;
    --custom-upload-dialog-time-font-size: 15px;
    --custom-upload-dialog-line-height: 21px;
    --custom-upload-dialog-datetime-gap: 5.15px;

    --custom-close-width: 75px;
    --custom-close-height: 40px;
    --custom-close-padding: 5.618px 16.853px;
    --custom-close-gap: 11.235px;
    --custom-close-font-size: 13.125px;
    --custom-close-border-radius: 3.371px;

    --custom-settings-font-size: 10px;
    --custom-settings-line-height: 16.44px;

    --custom-edit-header-tag-font-size: 20px;
    --custom-edit-header-tag-line-height: 20px;
    --custom-edit-font-size: 16.175px;
    --custom-edit-line-height: 20px;
    --custom-edit-header-font-weight: 700;
    --custom-edit-header-value-font-weight: 400;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2047px) {
  :root {
    --custom-font-size: 17.18px;
    --custom-user-impacted-font-size: 14.68px;

    // Dashboard
    --custom-dashboard-author-font-size: 11.71px;

    // Tab
    --custom-tab-height: 67px;
    --custom-tab-gap: 2.13px;
    --custom-tab-width: 225px;
    --custom-nav-width: 70px;

    // Header
    --custom-page-header-border-element-height: 7.5px;
    --custom-page-header-border-thickness: 0.44px;
    --custom-page-header-height: 60px;
    --custom-page-header-padding: 11.09px; //30.05px;
    --custom-page-header-upload-button-font-size: 15.413px;

    // Upload Popup

    --custom-upload-logo-width: 169.731px;
    --custom-upload-logo-height: 22.5px;

    --custom-upload-dialog-width: 885px;
    --custom-upload-dialog-height: 510px;
    --custom-upload-dialog-border-radius: 5.618px;

    --custom-upload-grid-margin: 15px;
    --custom-upload-dialog-grid-header-font-size: 15px;
    --custom-upload-dialog-grid-header-line-height: 15.729px;

    --custom-upload-dialog-header-height: 45px;
    --custom-upload-dialog-header-content-gap: 13.15px;
    --custom-upload-dialog-header-filter-text-gap: 8px;
    --custom-upload-dialog-header-filter-gap: 14px;
    --custom-upload-dialog-header-filter-toggle-width: 45px;
    --custom-upload-dialog-header-filter-toggle-height: 22.05px;
    --custom-upload-dialog-header-filter-text-font-size: 15px;
    --custom-uploader-header-text-image-gap: 15px;

    --custom-uploader-header-sort-image-width: 13px;
    --custom-uploader-header-sort-image-height: 12.8px;
    --custom-uploader-header-sort-image-gap: 3px;

    --custom-upload-dialog-cell-padding: 11.235px;

    --custom-document-name-font-size: 15px;
    --custom-document-name-line-height: 21px;

    --custom-document-name-other-font-size: 12px;
    --custom-document-name-other-line-height: 8.988px;

    --custom-upload-dialog-date-font-size: 15px;
    --custom-upload-dialog-time-font-size: 15px;
    --custom-upload-dialog-line-height: 21px;
    --custom-upload-dialog-datetime-gap: 5.15px;

    --custom-close-width: 75px;
    --custom-close-height: 40px;
    --custom-close-padding: 5.618px 16.853px;
    --custom-close-gap: 11.235px;
    --custom-close-font-size: 13.125px;
    --custom-close-border-radius: 3.371px;

    --custom-settings-font-size: 10px;
    --custom-settings-line-height: 16.44px;

    --custom-edit-header-tag-font-size: 20px;
    --custom-edit-header-tag-line-height: 20px;
    --custom-edit-font-size: 16.175px;
    --custom-edit-line-height: 20px;
    --custom-edit-header-font-weight: 700;
    --custom-edit-header-value-font-weight: 400;
  }
}

@media screen and (min-width: 2048px) {
  :root {
    --custom-font-size: 17.615px;
    --custom-user-impacted-font-size: 14.68px;

    // Dashboard
    --custom-dashboard-author-font-size: 11.71px;

    // Tab
    --custom-tab-height: 68px;
    --custom-tab-gap: 2.13px;
    --custom-tab-width: 225px;
    --custom-nav-width: 70px;

    // Header
    --custom-page-header-border-element-height: 7.5px;
    --custom-page-header-border-thickness: 0.44px;
    --custom-page-header-height: 60px;
    --custom-page-header-padding: 11.09px; //30.05px;
    --custom-page-header-upload-button-font-size: 15.413px;

    // Upload Popup

    --custom-upload-logo-width: 169.731px;
    --custom-upload-logo-height: 22.5px;

    --custom-upload-dialog-width: 885px;
    --custom-upload-dialog-height: 510px;
    --custom-upload-dialog-border-radius: 5.618px;

    --custom-upload-grid-margin: 15px;
    --custom-upload-dialog-grid-header-font-size: 15px;
    --custom-upload-dialog-grid-header-line-height: 15.729px;

    --custom-upload-dialog-header-height: 45px;
    --custom-upload-dialog-header-content-gap: 13.15px;
    --custom-upload-dialog-header-filter-text-gap: 8px;
    --custom-upload-dialog-header-filter-gap: 14px;
    --custom-upload-dialog-header-filter-toggle-width: 45px;
    --custom-upload-dialog-header-filter-toggle-height: 22.05px;
    --custom-upload-dialog-header-filter-text-font-size: 15px;
    --custom-uploader-header-text-image-gap: 15px;

    --custom-uploader-header-sort-image-width: 13px;
    --custom-uploader-header-sort-image-height: 12.8px;
    --custom-uploader-header-sort-image-gap: 3px;

    --custom-upload-dialog-cell-padding: 11.235px;

    --custom-document-name-font-size: 15px;
    --custom-document-name-line-height: 21px;

    --custom-document-name-other-font-size: 12px;
    --custom-document-name-other-line-height: 8.988px;

    --custom-upload-dialog-date-font-size: 15px;
    --custom-upload-dialog-time-font-size: 15px;
    --custom-upload-dialog-line-height: 21px;
    --custom-upload-dialog-datetime-gap: 5.15px;

    --custom-close-width: 75px;
    --custom-close-height: 40px;
    --custom-close-padding: 5.618px 16.853px;
    --custom-close-gap: 11.235px;
    --custom-close-font-size: 13.125px;
    --custom-close-border-radius: 3.371px;

    --custom-settings-font-size: 10px;
    --custom-settings-line-height: 16.44px;

    --custom-edit-header-tag-font-size: 20px;
    --custom-edit-header-tag-line-height: 20px;
    --custom-edit-font-size: 16.175px;
    --custom-edit-line-height: 20px;
    --custom-edit-header-font-weight: 700;
    --custom-edit-header-value-font-weight: 400;
  }
}

.text-read {
  font-weight: 400 !important;
}

.text-unread {
  font-weight: 700 !important;
}

.flex {
  display: flex;
}

.flex-basis {
  flex: 1;
}

.flex-contain {
  flex: 1 0 auto;
}

.flex-basis-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.fit-container {
  width: 100%;
  height: 100%;
}

.flex-fit-basis {
  @extend .flex, .fit-container, .flex-basis;
}

.flex-h-align-center {
  justify-content: center !important;
}

.flex-auto-justify-end {
  display: flex;
  flex: auto;
  justify-content: space-between;
}

.flex-v-align-center {
  align-items: center;
}

.flex-align-center {
  justify-content: center;
  align-items: center;
}

.flex-align-end {
  justify-content: flex-end;
}

.grid-container {
  display: grid;
}
