@use "../../../common/css/base" as base;

.menu-status-text {
  padding: 0.1rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
}

.menu-market-grid {
  grid-template-columns: repeat(3, 2fr);
  grid-template-rows: repeat(10, 1fr);
  grid-auto-flow: column;
  overflow: none;
}

.market-selection {
  background-color: base.$ap-yellow !important;
}
