@use '../../../common/css/base' as base;

.active-lease {
  color: base.$ap-dark-orange !important;
}
.in-lease {
  color: base.$ap-white !important;
}
.in-lease:hover {
  color: base.$ap-black;
}
.dead-deal {
  color: base.$ap-medium-gray !important;
}

.context-menu-item {
  &:hover {
    .context-menu-text {
      color: base.$ap-black !important;
    }
  }
}

.context-menu-text {
  font-size: 1.15rem !important;
  line-height: 1.5rem !important;
  font-weight: 700;
}
.active-context-menu-text {
  background-color: base.$ap-yellow !important;
  .context-menu-text {
    color: base.$ap-black !important;
  }
}
